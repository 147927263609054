import React from "react"

import Layout from "../modules/layout"
import SEO from "../components/seo"
import Chef from "../components/chef"

export default () => (
  <Layout>
    <SEO
      title="Home"
      description=">Cooking Assistant - coming soon."
    />
    <div>
      <Chef/>
    </div>
  </Layout>
)