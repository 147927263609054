import React from "react"
import styles from "./footer.module.scss"

export default () => (
  <footer className={styles.container}>
		<ul>
			<li><a href="mailto:domawsmith@gmail.com?subject=Portfolio">email me</a></li>
			<li>Cooking Assistant &copy; {new Date().getFullYear()}</li>
		</ul>
	</footer>
)
